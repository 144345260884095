import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TopicsService {

  apiUrl = environment.apiUrl;
  private generateUrl = environment.apiUrl + '/upload';

  constructor(private http: HttpClient, private logger: NGXLogger) { }

  getAllTopicsBySubId(sbjId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/topics/${sbjId}`);
  }

  createTopic(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/topics`, data);
  }

  updateTopic(topId: string, data: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/topics/${topId}`, data);
  }

  deleteTopic(topId: string, data: any): Observable<any> {
    return this.http.delete(`${this.apiUrl}/topics/${topId}`, { body: data });
  }

  getAllCourseContentsByTopId(topId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/coursecontents/${topId}`);
  }

  createCourseContent(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/coursecontents`, data);
  }

  updateCourseContent(conId: string, data: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/coursecontents/${conId}`, data);
  }

  deleteCourseContent(conId: string, data: any): Observable<any> {
    return this.http.delete(`${this.apiUrl}/coursecontents/${conId}`, { body: data });
  }

  generatePresignedUrl(data: any, token: any): Observable<Object> {
    const headerOpt = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
    });
    const requestOpt = { headers: headerOpt };
    return this.http.post(`${this.generateUrl}`, data, requestOpt);
  }

  uploadDocumentDetails(file: any, url: string): any {
    this.logger.log('url : ', url);
    // this.logger.log('file : ', file);
    const headers = new HttpHeaders()
      .set('content-type', '');

    return this.http.put(url, file, {
      headers: headers,
    });
  }

  // deletes3Document(data: any, token: any): Observable<Object> {
  //   const headerOpt = new HttpHeaders({
  //     'Authorization': `Bearer ${token}`,
  //   });
  //   const requestOpt = { headers: headerOpt };
  //   return this.http.post(`${this.generateUrl}/deletedocs`, data, requestOpt);
  // }

  deleteS3Document(data: any, token: any) {
    return this.http.post(`${this.generateUrl}/deletedocs`, data);
  }

  getDocsFromDB(data: any) {
    return this.http.post(`${this.generateUrl}/get-db-docs`, data);
  }

  getDocsForModule(data: any) {
    return this.http.post(`${this.generateUrl}/getattachments`, data);
  }

  getDocsOfClasswork(data: any) {
    return this.http.post(`${this.generateUrl}/gettopicattachment`, data);
  }

  getAllProgrammesByOrgId(orgId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/programs/${orgId}`);
  }
  getAllCohortsByProId(proId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/cohorts/${proId}`);
  }

  getAllSubjectsByCohId(cohId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/subjects/${cohId}`);
  }


  getAllGroups(): Observable<any> {
    return this.http.get(`${this.apiUrl}/groups`);
  }

  getAllGroups2(): Observable<any> {
    return this.http.get(`${this.apiUrl}/allgroups`,);
  }

  getAllGroupsByRole(data: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/allgroups`, {
      params: new HttpParams({
        fromObject: {
          role: data.role,
          tutor: data.tutor
        },
      }),
    });
  }

  createGroup(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/groups`, data);
  }

  updateGroup(data: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/groups`, data);
  }

  deleteGroup(data: any): Observable<any> {
    return this.http.delete(`${this.apiUrl}/groups`, { body: data });
  }
}
