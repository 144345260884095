import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { QuestionnaireService } from 'app/routes/questionnaire/questionnaire.service';
import { TopicsService } from 'app/routes/topics/topics.service';
import { TutorsService } from 'app/routes/tutors/tutors.service';
import notify from 'devextreme/ui/notify';
import * as uuid from 'uuid';

@Component({
  selector: 'app-add-courses',
  templateUrl: './add-courses.component.html',
  styleUrls: ['./add-courses.component.scss']
})
export class AddCoursesComponent implements OnInit {


  formCreateCourse!: FormGroup;
  imgFileName: any;
  uploadedURLImg: string = '';
  presignedURLImg: any;
  uploadFIleImg: any[] = [];
  uploadImg: boolean = false;
  errorSubmit: boolean = false;
  token1: any;
  uu: any;
  editView: boolean = false;
  courseData: any;
  courseId: string = '';

  disableUploadImg: boolean = true;
  questionnaireCount: any;


  defaultTexts = {
    curriculum: "This course is structured to cover all essential aspects of the topic. Detailed curriculum information will be provided soon. Please check back for updates or contact our support team for specific inquiries.",
    instructor: "IntelliTutor",
    overview: "Welcome to our comprehensive course designed to enhance your knowledge and skills. Detailed course information will be available shortly. Stay tuned for more updates.",
    faqs: "Frequently Asked Questions (FAQs) will be provided to address common concerns and inquiries related to this course. Please visit this section later for detailed information."
  };
  tutors: any;
  isTutor: boolean = false;

  constructor(
    private questionnaireService: QuestionnaireService,
    private topicsService: TopicsService,
    private router: Router,
    private tutorService: TutorsService) {
    this.formCreateCourse = new FormGroup({
      Title: new FormControl('', [Validators.required]),
      Overview: new FormControl(''),
      Curriculum: new FormControl(''),
      Instructor: new FormControl(''),
      FAQs: new FormControl(''),
      Duration: new FormControl(''),
      Tutor: new FormControl('',)
    });
  }

  ngOnInit(): void {
    this.uu = uuid.v4();
    if (sessionStorage.getItem('User_Group') === "Tutor") {
      this.isTutor = true
      const tutor = sessionStorage.getItem('User_Email');
      this.formCreateCourse.patchValue({
        Tutor: tutor
      });
    }
    if (sessionStorage.getItem('coursedata')) {
      this.editView = true;
      this.courseData = sessionStorage.getItem('coursedata');
      let courseDetails = JSON.parse(this.courseData);
      console.log('course data :', courseDetails);
      this.courseId = courseDetails.SK;
      this.questionnaireCount = courseDetails.QuestionnaireCount ?? 0
      this.formCreateCourse.patchValue({
        Title: courseDetails.GroupName,
        Overview: courseDetails.Overview ? courseDetails.Overview : '',
        Curriculum: courseDetails.Curriculum ? courseDetails.Curriculum : '',
        Instructor: courseDetails.Instructor ? courseDetails.Instructor : '',
        FAQs: courseDetails.FAQs ? courseDetails.FAQs : '',
        Duration: courseDetails.Duration ? courseDetails.Duration : '',
        Tutor: courseDetails.Tutor ? courseDetails.Tutor : '',
      });
      if (courseDetails.ImageName && courseDetails.ImageLocation) {
        this.uploadFIleImg.push({
          name: courseDetails.ImageName,
          psurl: '',
          location: courseDetails.ImageLocation
        });
        this.imgFileName = courseDetails.ImageName;
        this.uploadedURLImg = courseDetails.ImageLocation;
      }
    } else {
      this.editView = false;
    }
    this.getTutors()
  }

  // file upload img
  async uploadingFIleImg(file: any, event: Event) {
    const input = event.target as HTMLInputElement;
    let imageExt = file[0].type.split('/');
    console.log('on file upload img: ', file);
    this.imgFileName = file[0].name;
    this.uploadedURLImg = 'group_images/' + `GRO#${this.uu}` + '/' + this.imgFileName;
    let obj = {
      name: file[0].name,
      size: file[0].size,
      type: file[0].type,
      extention: imageExt[1],
      location1: this.uploadedURLImg,
      location2: `group_images/GRO#${this.uu}`,
      section: 'image upload'
    }
    this.questionnaireService.generatePresignedUrl(obj, this.token1).subscribe(res => {
      console.log('res from pre designed url : ', res);
      let d = JSON.parse(JSON.stringify(res))
      this.presignedURLImg = d.presignedUrl;
      this.questionnaireService.uploadDocumentDetails(file[0], this.presignedURLImg).subscribe(async (res: any) => {
        console.log(JSON.stringify(res));
        this.uploadFIleImg.push({
          name: this.imgFileName,
          psurl: this.presignedURLImg,
          location: this.uploadedURLImg
        });
        this.uploadImg = this.uploadFIleImg.length > 0 ? true : false;
      }, (error: any) => {
        console.error(JSON.stringify(error));
      });
    });

  }

  deleteDocumentImg(doc: any) {
    console.log('delete doc clicked :', doc);
    let obj = {
      fileName: doc.location,
      section: 'image upload'
    }
    this.questionnaireService.deletes3File(obj).subscribe(res => {
      console.log('res from delete :', res);
      const data1 = JSON.parse(JSON.stringify(res));
      if (data1.mesg == 'Success') {
        this.uploadFIleImg = [];
        this.disableUploadImg = false;
        const input = document.getElementById('myFileIMG') as HTMLInputElement;
        this.imgFileName = '';
        this.uploadedURLImg = '';
        if (input) {
          input.value = '';
        }
      }
    })
  }

  clickCreate() {
    if (this.formCreateCourse.valid) {
      console.log("Form is valid :", this.formCreateCourse.value);
      const queData = {
        Id: this.uu,
        Title: this.formCreateCourse.get('Title')?.value,
        Overview: this.formCreateCourse.get('Overview')?.value || this.defaultTexts.overview,
        Curriculum: this.formCreateCourse.get('Curriculum')?.value || this.defaultTexts.curriculum,
        Instructor: this.formCreateCourse.get('Instructor')?.value || this.defaultTexts.instructor,
        FAQs: this.formCreateCourse.get('FAQs')?.value || this.defaultTexts.faqs,
        Duration: this.formCreateCourse.get('Duration')?.value,
        Location: this.uploadedURLImg,
        Filename: this.imgFileName,
        Tutor: this.formCreateCourse.get('Tutor')?.value,
      }
      console.log("Create course obj :", queData);
      this.topicsService.createGroup(queData).subscribe(res => {
        console.log("Course created successfully :", res);
        notify({
          message: "Successfully Added Course", type: 'success',
          width: 300, shading: true
        }, { position: "top right", direction: "up-push" });
        this.router.navigate(['courses']);
      });
    } else {
      console.log("Form is invalid");
    }
  }

  goBack(): void {
    this.router.navigateByUrl('/courses');
  }

  clickSave() {
    if (this.formCreateCourse.valid) {
      console.log("Form is valid :", this.formCreateCourse.value);

      const queData = {
        Id: this.courseId,
        Title: this.formCreateCourse.get('Title')?.value,
        Overview: this.formCreateCourse.get('Overview')?.value || this.defaultTexts.overview,
        Curriculum: this.formCreateCourse.get('Curriculum')?.value || this.defaultTexts.curriculum,
        Instructor: this.formCreateCourse.get('Instructor')?.value || this.defaultTexts.instructor,
        FAQs: this.formCreateCourse.get('FAQs')?.value || this.defaultTexts.faqs,
        Duration: this.formCreateCourse.get('Duration')?.value,
        Location: this.uploadedURLImg ? this.uploadedURLImg : '',
        Filename: this.imgFileName ? this.imgFileName : '',
        QuestionnaireCount: this.questionnaireCount,
        Tutor: this.formCreateCourse.get('Tutor')?.value,
      }
      console.log("Update course obj :", queData);
      this.topicsService.updateGroup(queData).subscribe(res => {
        console.log("Course updated successfully :", res);
        notify({
          message: "Successfully updated Course", type: 'success',
          width: 300, shading: true
        }, { position: "top right", direction: "up-push" });
        this.router.navigate(['courses']);
      });
    } else {
      console.log("Form is invalid");
    }
  }

  getTutors() {
    this.tutorService.getAllTutors().subscribe(res => {
      const tutorData = res['data']['Items'] || [];
      this.tutors = tutorData.map((tutor: any) => ({
        ...tutor,
        fullName: `${tutor.FirstName} ${tutor.LastName}`
      }));
      console.log('Tutors:', this.tutors);
    })
  }
}
