<div class="container">
    <div class="row">
        <div class="col-md-8">
            <div class="col-md-12">
                <h3>Users</h3>
            </div>
            <div class="row mb-2 bread-crumb">
                <div class="col-md-12"><a href="/allusers">Users</a> > View All</div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="row mb-3">
                <div class="col-md-9 custom-align">
                    <!-- <input type="text" placeholder="Search" class="form-control custom-search" /> -->
                </div>
                <div class="col-md-3 custom-align">
                    <!-- <dx-button (onClick)="addQuestionnaire()">Add New</dx-button> -->
                </div>
                <!-- <div class="col-md-3 custom-align">
                  <dx-button class="add-btn" (onClick)="viewQuestionnaire()">View</dx-button>
                </div> -->
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <dx-data-grid id="programsGridContainer" [dataSource]="gridData"
                          [hoverStateEnabled]="true"
                          [showBorders]="true">
                <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                <dxo-search-panel [visible]="true" placeholder="Search..."></dxo-search-panel>
                <dxo-scrolling rowRenderingMode="virtual"> </dxo-scrolling>
                <dxi-column dataField="PK" caption="User Id" [visible]="false">
                </dxi-column>
                <dxi-column dataField="FirstName" caption="First Name"> </dxi-column>
                <dxi-column dataField="LastName" caption="Last Name"> </dxi-column>
                <dxi-column dataField="email" caption="Email"> </dxi-column>
                <dxi-column dataField="CreatedOn" caption="Created On" dataType="date">
                </dxi-column>

            </dx-data-grid>
        </div>
    </div>
</div>