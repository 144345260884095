import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from './auth.service';
import { AmplifyService } from '@core/aws-amplify';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private auth: AuthService, private logger: NGXLogger, private router: Router, private amplifyService: AmplifyService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    // const expectedRoles = route.data.roles as string[];
    // this.logger.log('logged user roles:', expectedRoles);
    // this.logger.log('this.auth.isLoggedIn():', this.auth.isLoggedIn());

    // const userRole: any = this.auth.getUserRole();
    // this.logger.log('userRole:', userRole, typeof userRole);

    // if (!expectedRoles.includes(userRole)) {
    //   this.router.navigate(['403']);
    //   return false;
    // }

    // return true;

    return new Promise<boolean>((resolve, reject) => {
      setTimeout(() => {
        const expectedRoles = route.data.roles as string[];
        const userRole: any = this.auth.getUserRole();
        this.logger.log('logged user roles:', expectedRoles);
        this.logger.log('this.auth.isLoggedIn():', this.auth.isLoggedIn());
        this.logger.log('userRole:', userRole, typeof userRole);

        if (!userRole || !expectedRoles.includes(userRole)) {
          this.router.navigate(['403']);
          resolve(false);
        } else {
          resolve(true);
        }
      }, 3000); // 3 seconds
    });
  }


}
