import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import CustomStore from 'devextreme/data/custom_store';
import { TopicsService } from '../topics/topics.service';
import { QuestionnaireService } from '../questionnaire/questionnaire.service';
import * as uuid from 'uuid';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {
  emailValue = '';
  gridData!: CustomStore;
  imgFileName: any;
  uploadedURLImg: any;
  presignedURLImg: any;
  uploadFIleImg: any[] = [];
  uploadImg: boolean = false;
  errorSubmit: boolean = false;
  token1: any;
  uu: any;

  constructor(
    private router: Router,
    private topicsService: TopicsService,
    private questionnaireService: QuestionnaireService,
  ) {

    this.token1 = sessionStorage.getItem('token')!;
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.uu = uuid.v4();
    this.gridData = new CustomStore({
      key: 'SK',
      load: (loadOptions) => {
        const userRole = sessionStorage.getItem('User_Group');
        const tutor = sessionStorage.getItem('User_Email')
        console.log("userRole: ", userRole);
        let data;
        if (userRole === 'Tutor') {
          data = {
            tutor: tutor,
            role: "Tutor"
          }
        } else if (userRole === 'SuperAdmin') {
          data = { role: "SuperAdmin" }
        }
        return this.topicsService.getAllGroupsByRole(data).toPromise()
          .then(
            res => {
              console.log("All group fetched from the DB: ", res);
              return {
                data: res['data']['Items'] ? res['data']['Items'] : [],
                totalCount: res['data']['Count'] ? res['data']['Count'].length : 0
              };
            }

          )
          .catch((error: any) => {
            console.error("All group fetched from the DB failed: ", error);
            return {
              data: [],
              totalCount: 0
            };
            // throw 'Data loading error'
          });
      }
    });
  }

  // addGroup() {
  //   if(this.emailValue && this.uploadImg) {
  //     this.errorSubmit = false;
  //     console.log(this.emailValue);

  //     let data = {
  //       name: this.emailValue,
  //       filename: this.imgFileName ,
  //       location: this.uploadedURLImg !== '' ? this.uploadedURLImg : '',
  //       id: this.uu
  //     }
  //     this.topicsService.createGroup(data).subscribe( res => {
  //       console.log('create group response :', res);
  //       this.loadData();
  //       this.emailValue = ''; 
  //       (document.getElementById('myFileIMG') as HTMLInputElement).value = '';
  //     });
  //   } else {
  //     this.errorSubmit = true;
  //   }
  // }

  deleteGroup(e: any) {
    console.log('delete group clicked :', e);
    let data = {
      ID: e.row.key
    }
    this.topicsService.deleteGroup(data).subscribe(res => {
      console.log('delete group response :', res);
      notify({
        message: "Successfully Deleted Course", type: 'success',
        width: 300, shading: true
      }, { position: "top right", direction: "up-push" });
      this.loadData();
    });
  }

  clickGoBack() {
    this.router.navigateByUrl('/dashboard');
  }

  // file upload img
  // async uploadingFIleImg(file: any, event: Event) {
  //   const input = event.target as HTMLInputElement;
  //   let imageExt = file[0].type.split('/');
  //   console.log('on file upload img: ', file);
  //   this.imgFileName = file[0].name;
  //   this.uploadedURLImg = 'group_images/' + `GRO#${this.uu}` + '/' + this.imgFileName;
  //   let obj = {
  //     name: file[0].name,
  //     size: file[0].size,
  //     type: file[0].type,
  //     extention: imageExt[1],
  //     location1: this.uploadedURLImg,
  //     location2: `group_images/GRO#${this.uu}`,
  //     section: 'image upload'
  //   }
  //   this.questionnaireService.generatePresignedUrl(obj, this.token1).subscribe(res => {
  //     console.log('res from pre designed url : ', res);
  //     let d = JSON.parse(JSON.stringify(res))
  //     this.presignedURLImg = d.presignedUrl;
  //     this.questionnaireService.uploadDocumentDetails(file[0], this.presignedURLImg).subscribe(async (res: any) => {
  //       console.log(JSON.stringify(res));
  //       this.uploadFIleImg.push({
  //         name: this.imgFileName,
  //         psurl: this.presignedURLImg,
  //         location: this.uploadedURLImg
  //       });
  //       this.uploadImg = this.uploadFIleImg.length > 0 ? true : false;
  //     }, (error: any) => {
  //       console.error(JSON.stringify(error));
  //     });
  //   });

  // }

  // deleteDocumentImg(doc: any) {
  //   console.log('delete doc clicked :', doc);
  //   let obj = {
  //     fileName: doc.location,
  //     section: 'image upload'
  //   }
  //   this.questionnaireService.deletes3File(obj).subscribe(res => {
  //     console.log('res from delete :', res);
  //     const data1 = JSON.parse(JSON.stringify(res));
  //     if (data1.mesg == 'Success') {
  //       this.uploadFIleImg = [];
  //       this.uploadImg = false;
  //       const input = document.getElementById('myFileIMG') as HTMLInputElement;
  //       if (input) {
  //         input.value = '';
  //       }
  //     }
  //   })

  // }

  addCourse() {
    sessionStorage.removeItem("coursedata");
    this.router.navigateByUrl('/courses/add');
  }

  editGroup(e: any) {
    sessionStorage.setItem("coursedata", JSON.stringify(e.row.data));
    this.router.navigateByUrl('/courses/edit');
  }

}
