<!-- <div class="d-flex w-full h-full form" *ngIf="show">
  <mat-card class="m-auto" style="max-width: 380px;">
    <mat-card-header class="m-b-24">
      <mat-card-title>Login</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <form class="form-field-full" [formGroup]="signInForm">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Email" (keyup)="errorRemove()" formControlName="email"
                 required>
          <mat-error *ngIf="f.email.errors?.required">Email address is required.</mat-error>
          <mat-error *ngIf="f.email.errors?.pattern">Enter a valid email.</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput placeholder="Password" [type]="!hide ? 'password' : 'text'"
                 formControlName="password" required (keyup)="errorRemove()">
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' :'visibility_off'
            }}</mat-icon>
          <mat-error *ngIf="f.password.errors?.required">password is required.</mat-error>
          <mat-error *ngIf="f.password.errors?.pattern">Enter a valid password.</mat-error>
        </mat-form-field>
        <div class="Error" *ngIf="invalidUser">Invalid username or password!</div>

        <mat-checkbox formControlName="rememberMe">{{'remember_me' | translate}}
        </mat-checkbox>

        <button class="w-full m-y-16" mat-raised-button color="primary"
                [disabled]="!!signInForm.invalid" [loading]="isSubmitting"
                (click)="onSignIn()">{{'login' | translate}}</button>

        <hr class="MuiDivider-root jss99">
        <div>
          <a class="MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-subtitle2 MuiTypography-colorSecondary MuiTypography-alignCenter"
             (click)="onForgotPassword()">Forgot Your Password?</a>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div> -->

<div class="cont" *ngIf="show">
  <div class="row">
    <div class="col-lg-5 col-md-5 col-sm-5 column-01" style="height: 100vh;">
      <div class="img-01">
        <img class="img-site-logo" src="assets/images/QuizHub logo 2.png" />
      </div>
      <div>
        <img class="img-site-logo-2" src="/assets/images/Group 38.png" />
      </div>
    </div>
    <div class="col-lg-7 col-md-7 col-sm-7" style="height: 100vh;">
      <form class="form-field-full" [formGroup]="signInForm">

        <div class="cont-03">
          <div class="title-header">
            <p class="title-01">Welcome!</p>
            <p class="title-02">Login to Your Account</p>
          </div>
          <div class="title-form">
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput placeholder="Email" (keyup)="errorRemove()" formControlName="email"
                     required class="input-01">
              <mat-error *ngIf="f.email.errors?.required">Email address is required.</mat-error>
              <mat-error *ngIf="f.email.errors?.pattern">Enter a valid email.</mat-error>
              <p *ngIf="errorEmail">Email address is required.</p>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Password</mat-label>
              <input matInput placeholder="Password" (keyup)="errorRemove()"
                     formControlName="password"
                     required [type]="!hide ? 'password' : 'text'">
              <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' :'visibility_off'
                }}</mat-icon>
              <mat-error *ngIf="f.password.errors?.required">Password is required.</mat-error>
              <mat-error *ngIf="f.password.errors?.pattern">Enter a valid password.</mat-error>
              <!-- <mat-error *ngIf="this.signInForm.get('password')?.value == ''">Password is required.</mat-error> -->
              <p *ngIf="errorPassword">Password is required.</p>
            </mat-form-field>
            <div class="Error" *ngIf="invalidUser">Invalid username or password!</div>
            <hr class="hr-01">
          </div>
          <div class="remember-section">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <mat-checkbox>{{'remember_me' | translate}}
                </mat-checkbox>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="forgot-pw">
                  <a class="forgot-password"
                     style="font-size: 14px;" (click)="onForgotPassword()">Forgot Password?</a>
                </div>
              </div>
            </div>
          </div>
          <div class="login-btn-01">
            <button class="w-full m-y-16" mat-raised-button color="primary"
                    [disabled]="!!signInForm.invalid" [loading]="isSubmitting"
                    (click)="onSignIn()">{{'login' | translate}}</button>
          </div>
        </div>
      </form>

    </div>
  </div>
</div>