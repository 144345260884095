<app-auth-sign-in [authState]="authState"></app-auth-sign-in>
<app-auth-require-new-password [authState]="authState"></app-auth-require-new-password>
<app-auth-forgot-password [authState]="authState"></app-auth-forgot-password>
<div style="height: 100vh;" *ngIf="loading">
</div>
<!-- <ngx-loading [show]="loading" [config]="{ 
    animationType: animationTypes.threeBounce,
    backdropBorderRadius: '3px', 
    primaryColour: '#800080',
    secondaryColour: '#800080',
    tertiaryColour: '#800080',
    backdropBackgroundColour: '#00000000'
    }" ></ngx-loading> -->