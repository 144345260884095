import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {

  apiUrl = environment.apiUrl;
  private generateUrl = environment.apiUrl + '/questionnaire';
  private generateUrl2 = environment.apiUrl + '/questionnaire/generateurl';

  categoriesAndTags = {
    "Mathematics": [
      "Algebra",
      "Geometry",
      "Calculus",
      "Trigonometry",
      "Statistics",
      "Probability",
      "Number Theory",
      "Linear Algebra",
      "Discrete Mathematics",
      "Set Theory"
    ],
    "Science": ["Physics", "Chemistry", "Biology"],
    "History": ["Ancient History", "World Wars", "Modern History"],
    "Language Arts": ["Grammar", "Literature", "Writing"],
    "Geography": ["Physical Geography", "Human Geography", "Cartography"],
    "Technology": ["Programming", "Web Development", "Artificial Intelligence"],
    "Arts and Music": ["Painting", "Music Theory", "Sculpture"],
    "Health and Physical Education": ["Nutrition", "Fitness", "Sports"]
  };


  constructor(private http: HttpClient) { }

  createQuestionnaire(data: any): Observable<any> {
    return this.http.post(`${this.generateUrl}`, data);
  }

  generatePresignedUrl(data: any, token: any): Observable<Object> {
    const headerOpt = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
    });
    const requestOpt = { headers: headerOpt };
    return this.http.post(`${this.generateUrl2}`, data, requestOpt);
  }

  uploadDocumentDetails(file: any, url: string): any {
    console.log('url : ', url);
    // this.logger.log('file : ', file);
    const headers = new HttpHeaders()
      .set('content-type', '');

    return this.http.put(url, file, {
      headers: headers,
    });
  }

  getAllQuestionnaire(data: any): Observable<any> {
    return this.http.get(`${this.generateUrl}`, {
      params: new HttpParams({
        fromObject: {
          role: data.role,
          tutor: data.tutor
        },
      }),
    });
  }

  viewSingleQuestionnaire(data: any): Observable<any> {
    return this.http.post(`${this.generateUrl}/view-questionnaire`, data);
  }

  deletes3File(data: any) {
    return this.http.post(`${this.generateUrl}/delete-document`, data);
  }

  deleteQuestionnaire(data: any) {
    return this.http.put(`${this.generateUrl}/delete-questionnaire`, data);
  }

  getAllSubjectsByOrgId(orgId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/mainsubjects/${orgId}`);
  }

  getAllTopicsBySubId(sbjId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/topics/${sbjId}`);
  }

  createMainSubject(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/mainsubjects`, data);
  }

  updateMainSubject(sbjId: string, data: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/mainsubjects/${sbjId}`, data);
  }

  deleteMainSubject(sbjId: string, data: any): Observable<any> {
    return this.http.delete(`${this.apiUrl}/mainsubjects/${sbjId}`, { body: data });
  }

  updateQuestionnaire(data: any) {
    return this.http.put(`${this.generateUrl}/update-questionnaire`, data);
  }

  //get questionnaire presigned url
  getquizpresignedurl(data: any): Observable<any> {
    return this.http.post(`${this.generateUrl}/getpresignedurl`, data);
  }

  getFile(presignurl: string): Observable<Blob> {
    return this.http.get(presignurl, { responseType: 'blob' });
  }

  getCategoriesAndTags() {
    return this.categoriesAndTags;
  }


}
