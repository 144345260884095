<div class="d-flex w-full"
     [style.height]="show  ? '100vh !important' : '100% !important'">
  <mat-card *ngIf="show && !isCodeSent && !completed" class="m-auto" style="max-width: 380px;">

    <mat-card-header class="m-b-24">
      <mat-card-title>Forgot Password</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <h2 class="subheader">In order for us to send you a password link, please enter your email
        address.</h2>
      <form name="form" [formGroup]="forgotPasswordForm" (ngSubmit)="onSend()" novalidate
            autocomplete="off" class="form-field-full">
        <mat-form-field appearance="outline">
          <mat-label>Email Address</mat-label>
          <input matInput placeholder="Email" class="form-control" name="email"
                 formControlName="email"
                 (focus)="emailFocus=true" (focusout)="emailFocus=false" type="email"
                 oncopy="return false" onpaste="return false" oncut="return false">
        </mat-form-field>
        <mat-error *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <mat-error *ngIf="f.email.errors?.required">Email address is
            required.</mat-error>
          <mat-error *ngIf="f.email.errors?.pattern">Enter a valid email.</mat-error>
        </mat-error>
        <div class="form-group">
          <div class="alert alert-danger" *ngIf="errorMessage && !f.email.errors">
            {{ errorMessage }}
          </div>
        </div>
        <button class="w-full m-y-16" mat-raised-button color="primary"
                type="submit">Next</button>
        <hr class="MuiDivider-root jss99">
        <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-true buttonhover"><a
             class="MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-subtitle2 MuiTypography-colorSecondary MuiTypography-alignCenter"
             (click)="onSignIn()">Log In</a>
        </div>
      </form>
    </mat-card-content>

  </mat-card>
  <mat-card *ngIf="show && isCodeSent && !completed" class="m-auto" style="max-width: 380px;">
    <mat-card-header class="m-b-24">
      <mat-card-title>Forgot Password</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <h6 class="subheader">An email with your code has been sent to {{emailaddress}}.
      </h6>

      <form name="form" [formGroup]="updatePasswordForm" (ngSubmit)="onSubmit()"
            novalidate autocomplete="off" class="form-field-full">
        <mat-form-field appearance="outline">
          <mat-label>Security Code</mat-label>
          <input type="text" matInput placeholder="Security Code" class="form-control"
                 name="code"
                 formControlName="code" maxlength="6" pattern="\d*"
                 onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                 oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                 oncopy="return false" onpaste="return false" oncut="return false">
        </mat-form-field>
        <mat-error *ngIf="submitted && f.code.errors" class="invalid-feedback">
          <mat-error *ngIf="f.code.errors?.required">Security Code is
            required.</mat-error>
          <mat-error *ngIf="f.code.errors?.pattern">Security Code is a 6 digit
            number.</mat-error>
        </mat-error>
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput placeholder="Password" class="form-control"
                 [type]="!hide ? 'password' : 'text'"
                 name="password" formControlName="password"
                 pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                 oncopy="return false" onpaste="return false" oncut="return false">
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility'
            :'visibility_off' }}</mat-icon>
        </mat-form-field>
        <mat-error *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <mat-error *ngIf="f.password.errors?.required">Password is
            required.</mat-error>
          <mat-error *ngIf="f.password.errors?.pattern">
            Password must contain at least one number<br>
            Password must contain at least one @#$* symbol <br>
            Password must contain at least one uppercase letter<br>
            Password must contain at least one lowercase letter<br>
            Password length must be greater than 7 characters<br></mat-error>
        </mat-error>
        <mat-form-field appearance="outline">
          <mat-label>Confirm Password</mat-label>
          <input matInput placeholder="Confirm Password" class="form-control"
                 [type]="!hide ? 'password' : 'text'" name="confirmPassword"
                 formControlName="confirmPassword"
                 oncopy="return false" onpaste="return false" oncut="return false">
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility'
            :'visibility_off' }}</mat-icon>

        </mat-form-field>
        <mat-error *ngIf="submitted && f.confirmPassword.errors"
                   class="invalid-feedback">
          <mat-error *ngIf="f.confirmPassword.errors?.required">Confirm Password is
            required.</mat-error>
          <mat-error *ngIf="f.confirmPassword.errors?.mustMatch">Password & Confirm
            Password does not match.
          </mat-error>
        </mat-error>
        <div class="form-group">
          <div class="alert alert-danger"
               *ngIf="errorMessage && !f.code.errors && !f.password.errors && !f.confirmPassword.errors">
            {{ errorMessage }}
          </div>
        </div>
        <button class="w-full m-y-16" mat-raised-button color="primary"
                type="submit">Submit</button>

        <hr class="MuiDivider-root jss99">
        <a class="MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-subtitle2 MuiTypography-colorSecondary MuiTypography-alignCenter"
           (click)="onSend()">Resend Code</a> <br>
        <a class="MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-subtitle2 MuiTypography-colorSecondary MuiTypography-alignCenter"
           (click)="onSignIn()">Log In</a>
      </form>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="show && completed" class="m-auto" style="max-width: 380px;">
    <mat-card-header class="m-b-24">
      <mat-card-title>Forgot Password</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      {{clearData()}}
      <h1>Password Reset Successfully</h1>
      <h2>Congratulations!</h2>

      <p>Your password has been changed successfully. </p>

      <div class="countdown">
        We'll take you to the login page in <br>
      </div>
      <div class="countdown-timer">
        {{counterToShow}}</div>
      <hr class="MuiDivider-root jss99">
      <div class="MuiGrid-root MuiGrid-container ">
        <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-true buttonhover"><a
             class="MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-subtitle2 MuiTypography-colorSecondary MuiTypography-alignCenter"
             (click)="onSignIn()">Log In</a></div>
      </div>
    </mat-card-content>
  </mat-card>
</div>