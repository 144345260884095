import { NgxLoggerLevel } from "ngx-logger";

export const environment: any = {
  apiUrl: 'https://api.iqsparker.com/stage',
  production: false,
  baseUrl: '',
  useHash: false,
  logLevel: NgxLoggerLevel.TRACE,
  serverLogLevel: NgxLoggerLevel.ERROR,
  orgId: '9abbb7c2-7f11-4a9c-bb54-panaceata441',
  version: '1.1.0',
  NicRegex: new RegExp('^(([5-9]{1})([0-9]{1})([0-3568]{1})([0-9]{6})([vVxX]))|(([12]{1})([09]{1})([0-9]{2})([0-3568]{1})([0-9]{7}))$'),
};

export const awsconfig = {
  aws_project_region: 'eu-north-1',
  aws_cognito_region: 'eu-north-1',
  // aws_user_pools_id: 'eu-west-1_UGe6cy6LV',
  // aws_user_pools_web_client_id: '6vuep8dcq0lv0v1flvkcg4ndh',
  aws_user_pools_id: 'eu-north-1_mauroaE5P',
  aws_user_pools_web_client_id: '6pdmh4qt04q3vj3na5fr8qaj6n',
  oauth: {}
};