import { Component, Input, OnInit, Inject, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from '../auth.service';
import { AuthState, AmplifyService } from '@core/aws-amplify';
import { environment } from '@env/environment';
import { MustMatch } from '@shared/validators/must-match.validator';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-require-new-password',
  templateUrl: './require-new-password.component.html',
  styleUrls: ['./require-new-password.component.scss']
})
export class RequireNewPasswordComponent implements OnInit, OnChanges {
  // tslint:disable-next-line:variable-name
  _authState!: AuthState;
  show!: boolean;
  errorMessage!: any;
  submitted = false;
  loading = false;
  newPasswordForm: FormGroup;
  hide: any;
  confirmPasswordFocus!: boolean;
  passwordFocus!: boolean;
  userEmail: any;

  constructor(
    @Inject(AmplifyService) protected amplifyService: AmplifyService,
    private formBuilder: FormBuilder, private authService: AuthService,
    private logger: NGXLogger, private router: Router
  ) {
    this.newPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern(environment.passwordRegex)]],
      confirmPassword: ['', [Validators.required]]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  @Input()
  set authState(authState: AuthState) {
    this._authState = authState;
    this.show = authState.state === 'requireNewPassword';
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.newPasswordForm.controls;
  }

  ngOnInit() {
    if (!this.amplifyService.auth()) {
      throw new Error('Auth module not registered on AmplifyService provider');
    }
  }

  ngOnChanges() {
    window.scrollTo(0, 0);
  }

  async onSubmit() {
    this.submitted = true;
    this.logger.log(this.newPasswordForm.value);
    // stop here if form is invalid
    if (this.newPasswordForm.invalid) {
      return;
    }

    const { user } = this._authState;

    if (!user || !user.challengeParam) {
      this._setError('The application is in invalid state. Please refresh your browser.');
      return;
    }

    const { requiredAttributes } = user.challengeParam;
    const password = this.newPasswordForm.value.password;
    this.loading = true;
    this.amplifyService
      .auth()
      .completeNewPassword(user, password, requiredAttributes)
      .then((res: any) => {
        // console.log('require new pw 20');
        this.logger.log(`Forgot password request completed`, res);
        // event data
        this.logger.log('set pw completed');
        this.logger.log('user name : ', res.username);
        user.attributes = user.challengeParam.userAttributes;
        this.onAlertClose();
        if (user.challengeName === 'SMS_MFA' ||
          user.challengeName === 'SOFTWARE_TOKEN_MFA') {
          this.amplifyService.setAuthState({ state: 'confirmSignIn', user });
        } else {
          this.amplifyService.setAuthState({ state: 'signedIn', user });
          this.signIn2(password);
        }
      })
      .catch((err: any) => {
        this.logger.error(`Forgot password request failed`, JSON.stringify(err));
        this._setError(err);
      })
      .finally(() => this.loading = false);
    console.log('require new pw 4');
  }

  signIn2(pw: any) {
    const email = sessionStorage.getItem('User_Email');
    console.log('require new pw signIn2', email);
    this.amplifyService
      .auth()
      .signIn(email, pw)
      .then((user: any) => {
        // this.logger.log('user  pw', user);
        sessionStorage.setItem('token', user.signInUserSession.accessToken.jwtToken);
        // this.logger.log('user group  pw', user.signInUserSession.accessToken.payload['cognito:groups'][0]);
        sessionStorage.setItem('User_Group', user.signInUserSession.accessToken.payload['cognito:groups'][0]);
        const userGroup = user.signInUserSession.accessToken.payload['cognito:groups'][0];
        if (userGroup === 'BranchManager' || userGroup === 'FranchiseAdmin' || userGroup === 'SuperAdmin' || userGroup === 'Tutor') {
          const orgId = { orgId: environment.orgId }

          // get user permission list
          this.authService.getUserPermissions(userGroup, orgId).subscribe((res) => {
            this.logger.log('User role permissions:', res);
            const permissionList = res.data[0];
            this.logger.log('permissionList', permissionList);
            sessionStorage.setItem('Role_permissions', JSON.stringify(permissionList));
          })
          this.logger.log('Going to Dashboard');
        }
        else {
          this.amplifyService.auth().signOut();
          this.amplifyService.setAuthState({ state: 'signOut', user: null });
          this.router.navigateByUrl('/auth');
          return
        }
      })
  }

  onSignIn() {
    this.onAlertClose();
    this.amplifyService.setAuthState({ state: 'signIn', user: null });
  }

  onAlertClose() {
    this._setError(null);
  }

  _setError(err: any) {
    if (!err) {
      this.errorMessage = null;
      return;
    }

    this.errorMessage = err.message || err;
  }
}
