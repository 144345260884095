<div>
    <div class="row">
        <div class="col-md-8">
            <div class="col-md-12">
                <h3 *ngIf="!editView">Add Course</h3>
                <h3 *ngIf="editView">Edit Course</h3>
            </div>
            <div class="row mb-2 bread-crumb">
                <div class="col-md-12"><a href="/courses">Courses</a> > Add Course
                </div>
            </div>
        </div>
    </div>
    <div class="form-container">
        <form [formGroup]="formCreateCourse">

            <div class="row d-flex justify-content-center mb-3 mt-3">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-12 mb-2 bread-crumb">
                            <label for="title">Course Title</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-2 bread-crumb">
                            <dx-text-box formControlName="Title" style="border: 1px solid #7a2bef;"
                                         mode="text" [disabled]="editView"
                                         placeholder="Course Title"> </dx-text-box>
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-md-12 mb-2 bread-crumb">
                            <label for="title">Overview</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-2 bread-crumb">
                            <dx-text-area formControlName="Overview" [maxLength]="500"
                                          style="border: 1px solid #7a2bef;" mode="text"
                                          placeholder="Course Overview">
                            </dx-text-area>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-2 bread-crumb">
                            <label for="title">Curriculum</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-2 bread-crumb">
                            <dx-text-area formControlName="Curriculum" [maxLength]="500"
                                          style="border: 1px solid #7a2bef;" mode="text"
                                          placeholder="Course Curriculum">
                            </dx-text-area>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-2 bread-crumb">
                            <label for="title">Instructor</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-2 bread-crumb">
                            <dx-text-area formControlName="Instructor" [maxLength]="500"
                                          style="border: 1px solid #7a2bef;" mode="text"
                                          placeholder="Instructor Info"> </dx-text-area>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-2 bread-crumb">
                            <label for="title">FAQs</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-2 bread-crumb">
                            <dx-text-area formControlName="FAQs" [maxLength]="500"
                                          style="border: 1px solid #7a2bef;" mode="text"
                                          placeholder="Course FAQs"> </dx-text-area>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-12 mb-2 bread-crumb">
                            <label for="title">Duration</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-2 bread-crumb">
                            <dx-text-box formControlName="Duration"
                                         style="border: 1px solid #7a2bef;" mode="text"
                                         placeholder="Duration(hours)"> </dx-text-box>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 mb-2 bread-crumb">
                            <label for="tutor">Tutor</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-2 bread-crumb">
                            <dx-select-box
                                           style="border: 1px solid #7a2bef;"
                                           [dataSource]="tutors"
                                           displayExpr="fullName"
                                           valueExpr="email"
                                           placeholder="Select Tutor"
                                           formControlName="Tutor"
                                           [readOnly]="isTutor">
                            </dx-select-box>
                        </div>
                    </div>

                    <br />
                    <!-- attach image for questionnaire display -->
                    <div class="row">
                        <div class="col-md-12 mb-2 bread-crumb">
                            <label for="main-subject">Attach Image</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-2 bread-crumb text-center">
                            <div class="file-upload">
                                <label for="myFileIMG"
                                       [ngClass]="{'browser-button': disableUploadImg}">Browse</label>
                                <!-- <label for="myFileIMG">Browse</label> -->
                            </div>
                            <span>
                                <input type="file" id="myFileIMG"
                                       (change)="uploadingFIleImg($any($event).target.files, $event)"
                                       name="filename" />
                            </span>
                        </div>
                        <div *ngFor="let doc of uploadFIleImg">
                            <div class="card card-doc">
                                <div class="card-body row">
                                    <div class="col-sm-8">{{doc.name}}</div>
                                    <div class="col-sm-4">
                                        <div class="delete-button-container">
                                            <div class="row">
                                                <div class="col-md-12 mb-2 bread-crumb">
                                                    <button (click)="deleteDocumentImg(doc)"
                                                            class="delete">X</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="row">
            <!-- <p *ngIf="!(uploadFIle.length > 0 && formCreateQuestionnaire.valid)" class="errr-msg">Please complete all relevant fields before submitting the form.</p> -->
            <div class="col-md-2">
                <div class="row">
                    <div class="col-md-12 mb-2 bread-crumb">
                        <!-- <button class="back-btn" (click)="goBack()">Back</button> -->
                        <dx-button (onClick)="goBack()">Back</dx-button>
                    </div>
                </div>
            </div>
            <div class="col-md-8"></div>
            <div class="col-md-2">
                <div class="upload-button-container">
                    <div class="row">
                        <div class="col-md-12 mb-2 bread-crumb">
                            <dx-button *ngIf="editView" [disabled]="!(formCreateCourse.valid)"
                                       (click)="clickSave()" class="upload">Save</dx-button>
                            <dx-button *ngIf="!editView"
                                       [disabled]="!(uploadFIleImg.length > 0 && formCreateCourse.valid)"
                                       (click)="clickCreate()" class="upload">Create</dx-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>