<div class="matero-sidebar-header" *ngIf="showHeader">
  <app-branding></app-branding>

  <!-- <mat-slide-toggle *ngIf="showToggle"
                    [checked]="toggleChecked"
                    (change)="toggleCollapsed.emit()">
  </mat-slide-toggle> -->
</div>

<div class="matero-sidebar-main scrollbar-none">
  <!-- <app-user-panel *ngIf="showUser"></app-user-panel> -->
  <app-sidemenu [ripple]="showToggle"></app-sidemenu>
</div>