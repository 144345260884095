import { Component, OnInit } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { NGXLogger } from 'ngx-logger';
import { UsersService } from './users.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  gridData: any;

  constructor(private logger: NGXLogger,
    private userService: UsersService
  ) {
    this.loadUserData()
  }

  ngOnInit() {

  }

  loadUserData() {
    console.log("get users called")
    this.gridData = new CustomStore({
      key: 'SK',
      load: (loadOptions) => {
        return this.userService.getAllUsers().toPromise()
          .then(
            res => {
              this.logger.log("User Data fetched from the DB: ", res);
              return {
                data: res['data']['Items'] ? res['data']['Items'] : [],
                totalCount: res['data'] ? res['data'].length : 0
              };
            }

          )
          .catch((error: any) => {
            this.logger.error(" fetching user Data from the DB failed: ", error);
            return {
              data: [],
              totalCount: 0
            };
            // throw 'Data loading error'
          });
      }
    })
  }

}
