import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RoutesRoutingModule } from './routes-routing.module';
import { KatexModule } from 'ng-katex';
import { GroupsModule } from './groups/groups.module';
import { DxDataGridModule, DxLoadPanelModule, DxPopupModule } from 'devextreme-angular';
import { FeedbackComponent } from './feedback/feedback.component';
import { UsersComponent } from './users/users.component';
import { TutorsComponent } from './tutors/tutors.component';





const COMPONENTS: any[] = [
];
const COMPONENTS_DYNAMIC: any[] = [];

@NgModule({
  imports: [SharedModule, RoutesRoutingModule,
    KatexModule,
    DxDataGridModule,
    DxLoadPanelModule,
    DxPopupModule],
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, FeedbackComponent, UsersComponent,],
})
export class RoutesModule { }
