import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '@env/environment';

import { AdminLayoutComponent } from '@theme/admin-layout/admin-layout.component';
import { AuthGuard } from '@core/authentication';
import { RoleGuard } from '@core/authentication/role.guard';
import { GroupsComponent } from './groups/groups.component';
import { AddCoursesComponent } from './groups/add-courses/add-courses.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { UsersComponent } from './users/users.component';
import { TestQuestionnaireComponent } from './test-questionnaire/test-questionnaire.component';


const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      // {
      //   path: '',
      //   loadChildren: () => import('./questionnaire/questionnaire.module').then(m => m.QuestionnaireModule),
      //   canActivate: [RoleGuard], data: { roles: ['SuperAdmin', 'BranchManager', 'FranchiseAdmin'] }
      // },
      { path: '', redirectTo: 'questionnaire', pathMatch: 'full' },
      {
        path: 'questionnaire',
        loadChildren: () => import('./questionnaire/questionnaire.module').then(m => m.QuestionnaireModule),
        canActivate: [RoleGuard], data: { roles: ['SuperAdmin', "Tutor"] }
      },
      {
        path: 'mainsubject',
        loadChildren: () => import('./mainsubjects/mainsubject.module').then(m => m.MainSubjectModule),
        canActivate: [RoleGuard], data: { roles: ['SuperAdmin', "Tutor"] }
      },
      // {
      //   path: 'topics',
      //   loadChildren: () => import('./topics/topics.module').then(m => m.TopicsModule),
      //   canActivate: [RoleGuard], data: { roles: ['SuperAdmin', 'BranchManager', 'FranchiseAdmin'] }
      // },
      // {
      //   path: 'courses',
      //   loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule),
      //   // canActivate: [RoleGuard], data: { roles: ['SuperAdmin', 'BranchManager', 'FranchiseAdmin'] }
      // },
      // {
      //   path: 'groups',
      //   loadChildren: () => import('./groups/groups-routing.module').then(m => m.GroupsModule),
      //   canActivate: [RoleGuard], data: { roles: ['SuperAdmin', 'BranchManager', 'FranchiseAdmin'] }
      // },
      {
        path: 'courses',
        component: GroupsComponent
      },
      {
        path: 'courses/add',
        component: AddCoursesComponent
      },
      {
        path: 'courses/edit',
        component: AddCoursesComponent
      },
      {
        path: 'feedback',
        component: FeedbackComponent,
        canActivate: [RoleGuard], data: { roles: ['SuperAdmin'] }

      },
      {
        path: 'allusers',
        component: UsersComponent,
        canActivate: [RoleGuard], data: { roles: ['SuperAdmin'] }
      },
      {
        path: 'test-questionnaires',
        loadChildren: () => import('./test-questionnaire/test-questionnaire.module').then(m => m.TestQuestionnaireModule),
        canActivate: [RoleGuard], data: { roles: ['SuperAdmin', "Tutor"] }
      },
      {
        path: 'tutors',
        loadChildren: () => import('./tutors/tutors.module').then(m => m.TutorsModule),
        canActivate: [RoleGuard], data: { roles: ['SuperAdmin'] }
      },
    ],
  },
  {
    path: 'auth',
    loadChildren: () => import('../core/authentication/auth.module')
      .then(m => m.AuthModule),
  },

  { path: '**', redirectTo: 'auth' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
    }),
  ],
  exports: [RouterModule],
})
export class RoutesRoutingModule { }
