import { Component, Input, OnInit, Inject, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@core';
import { AuthState, AmplifyService } from '@core/aws-amplify';
import { environment } from '@env/environment';
import { includes } from 'lodash';
import { NGXLogger } from 'ngx-logger';


@Component({
  selector: 'app-auth-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnChanges {

  // tslint:disable-next-line:variable-name
  _authState: AuthState | undefined;

  show: boolean = false;
  signInForm: FormGroup;
  submitted = false;
  isSubmitting = false;
  errorMessage: any = '';
  passData: any;
  hide: any;
  emailFocus: boolean = false;
  messageToken = false;
  deviceInfo = null;
  invalidUser!: boolean;
  errorEmail = false;
  errorPassword = false;

  constructor(private formBuilder: FormBuilder,
    @Inject(AmplifyService) protected amplifyService: AmplifyService, private router: Router, private logger: NGXLogger, private auth: AuthService) {
    this.signInForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(environment.emailRegex)]],
      password: ['', [Validators.required]]
    });
  }

  @Input()
  set authState(authState: AuthState) {
    this._authState = authState;
    // if (authState.state === 'signIn') {
    //   this.show = true;
    // } else if (authState.state === 'signedOut') {
    //   this.show = true;
    // } else if (authState.state === 'signedUp') {
    //   this.show = true;
    // } else {
    //   this.show = false;
    // }
    this.show = includes(['signIn', 'signedOut', 'signedUp'], authState.state);
    // this.logger.log('authState.user.email', authState.user.email);
    // this.signInForm.patchValue({
    //   password: authState.user ? authState.user.email || '' : ''
    // });
  }


  ngOnInit() {
    if (!this.amplifyService.auth()) {
      throw new Error('Auth module not registered on AmplifyService provider');
    }
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.signInForm.controls;
  }

  ngOnChanges() {
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
    // this.signInForm.patchValue({
    //   password: ''
    // });
  }

  onSignIn() {
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
    // this.logger.debug(this.signInForm.value);
    this.submitted = true;
    // stop here if form is invalid
    if (this.signInForm.invalid) {
      return;
    }
    const email = this.signInForm.value.email.toLowerCase();
    const password = this.signInForm.value.password;
    if (email == '' || password == '') {
      if (email == '') {
        this.errorEmail = true;
      } else if (password == '') {
        this.errorPassword = true;
      } else {
        this.errorEmail = true;
        this.errorPassword = true;
      }
    }
    else {
      this.errorEmail = false;
      this.errorPassword = false;
      sessionStorage.setItem('User_Email', email);
      this.auth.login(email, password);
      this.isSubmitting = true;
      this.amplifyService
        .auth()
        .signIn(email, password)
        .then(async (user: any) => {
          this.logger.log('user', user);
          this.logger.log('calling signup 6');
          if (user.signInUserSession) {
            sessionStorage.setItem('token', user.signInUserSession.accessToken.jwtToken);
            this.logger.log('user group', user.signInUserSession.accessToken.payload['cognito:groups'][0]);
            sessionStorage.setItem('User_Group', user.signInUserSession.accessToken.payload['cognito:groups'][0]);
            const userGroup = user.signInUserSession.accessToken.payload['cognito:groups'][0];
            if (userGroup === 'BranchManager' || userGroup === 'FranchiseAdmin' || userGroup === 'SuperAdmin' || userGroup === 'Tutor') {
              this.invalidUser = false;
              const orgId = { orgId: environment.orgId }

              // get user permission list
              this.auth.getUserPermissions(userGroup, orgId).subscribe((res) => {
                this.logger.log('User role permissions:', res);
                const permissionList = res.data[0];
                this.logger.log('permissionList', permissionList);
                sessionStorage.setItem('Role_permissions', JSON.stringify(permissionList));
              })
              this.logger.log('Going to Dashboard');
              // this.router.navigateByUrl('/questionnaire');
            }
            else {
              this.logger.log('Not Going to Dashboard');
              this.invalidUser = true;
              this.amplifyService.auth().signOut();
              this.amplifyService.setAuthState({ state: 'signOut', user: null });
              this.router.navigateByUrl('/auth');
              return
            }
          }

          if (
            user.challengeName === 'SMS_MFA' ||
            user.challengeName === 'SOFTWARE_TOKEN_MFA'
          ) {
            this.logger.log('calling signup 2');
            Object.assign(user, { attributes: { email } });
            this.amplifyService.setAuthState({
              state: 'confirmSignIn',
              user,
            });
          } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            console.log('NEW_PASSWORD_REQUIRED');
            this.amplifyService.setAuthState({
              state: 'requireNewPassword',
              user,
            });
          } else if (
            user.challengeName === 'CUSTOM_CHALLENGE' &&
            user.challengeParam &&
            user.challengeParam.trigger === 'true'
          ) {
            this.amplifyService.setAuthState({
              state: 'customConfirmSignIn',
              user,
            });
          }
          this.resetPassword();
          this.onAlertClose();
        })
        .catch((err: any) => {
          this.logger.error("PreAuthentication failed with error: ", JSON.stringify(err));
          err.message = err.message.replace('PreAuthentication failed with error ', '');
          if (err.message === 'User does not exist.') {
            err.message = 'Oops, looks like You’re not registered, please register Your account';
          }
          if (err.message === 'Account not found. Please contact customer service.') {
            err.message = 'shownewmessage';
          }
          this.setError(err);
        })
        .finally(() => {
          this.invalidUser = true;
          this.isSubmitting = false;
          // this.signInForm.reset();
          this.submitted = false;
          this.signInForm.patchValue({
            password: password
          });
        });
    }

  }

  onAlertClose() {
    this.setError(null);
  }

  onForgotPassword() {
    this.submitted = false;
    this.onAlertClose();
    this.amplifyService.setAuthState({ state: 'forgotPassword', user: { email: this.signInForm.value.email } });
  }

  onSignUp() {
    this.submitted = false;
    this.onAlertClose();
    this.amplifyService.setAuthState({ state: 'signUp', user: { email: this.signInForm.value.email } });
  }

  private resetPassword() {
    this.signInForm.patchValue({
      password: ''
    });
  }

  private setError(err: any) {
    if (!err) {
      this.errorMessage = null;
      return;
    }
    this.errorMessage = err.message || err;
    this.logger.error(this.errorMessage);
    // this.logger.error(this.errorMessage);
  }

  errorRemove() {
    this.invalidUser = false;
    // this.signInForm.get('password')?.setValue(this.signInForm.get('password')?.value);
  }
}
