<div>
    <div class="row">
        <div class="col-md-8">
            <div class="col-md-12">
                <h3>Courses</h3>
            </div>
            <div class="row mb-2 bread-crumb">
                <div class="col-md-12"><a href="/mainsubject">Courses</a> > View All</div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="row mb-3">
                <div class="col-md-9 custom-align">
                    <!-- <input type="text" placeholder="Search" class="form-control custom-search" /> -->
                </div>
                <div class="col-md-3 custom-align">
                    <dx-button class="add-btn" (onClick)="addCourse()">Add New</dx-button>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="row mt-5">
            <div class="col-md-12">
                <dx-data-grid id="programsGridContainer" [dataSource]="gridData"
                              [hoverStateEnabled]="true"
                              [showBorders]="true">
                    <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
                    <dxo-search-panel [visible]="true" placeholder="Search..."></dxo-search-panel>
                    <dxo-scrolling rowRenderingMode="virtual"> </dxo-scrolling>
                    <dxi-column dataField="GroupName" caption="Course Name"> </dxi-column>
                    <dxi-column dataField="Status" caption="Status"> </dxi-column>

                    <dxi-column width="8%" alignment="center" cellTemplate="editCellTemplate"
                                dataField="Edit"
                                caption="Edit"></dxi-column>
                    <dxi-column width="8%" alignment="center" cellTemplate="deleteCellTemplate"
                                dataField="Delete"
                                caption="Delete"></dxi-column>
                    <div class="eyeIcon" style="text-align: center"
                         *dxTemplate="let e of 'editCellTemplate'"
                         title="Edit">
                        <!-- <mat-icon>remove_red_eye</mat-icon> -->
                        <mat-icon (click)="editGroup(e)">edit</mat-icon>
                    </div>
                    <div class="eyeIcon" style="text-align: center"
                         *dxTemplate="let e of 'deleteCellTemplate'"
                         title="Delete">
                        <mat-icon (click)="deleteGroup(e)">delete_outline</mat-icon>
                    </div>
                </dx-data-grid>
            </div>
        </div>

        <div>
            <dx-button class="yes-btn" text="Back" (onClick)="clickGoBack()"></dx-button>

        </div>
    </div>
</div>