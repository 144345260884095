import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, iif, merge, of } from 'rxjs';
import { catchError, map, share, switchMap, tap } from 'rxjs/operators';
import { TokenService } from './token.service';
import { LoginService } from './login.service';
import { filterObject, isEmptyObject } from './helpers';
import { User } from './interface';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private user$ = new BehaviorSubject<User>({});
  private loggedIn = false;
  private userRole: string | undefined;
  userPermissions!: string;

  private change$ = merge(
    this.tokenService.change(),
    this.tokenService.refresh().pipe(switchMap(() => this.refresh()))
  ).pipe(
    switchMap(() => this.assignUser()),
    share()
  );

  constructor(private loginService: LoginService, private tokenService: TokenService,
    private logger: NGXLogger, private http: HttpClient) { }

  private baseUrl = environment.apiUrl;

  init() {
    return new Promise<void>(resolve => this.change$.subscribe(() => resolve()));
  }

  change() {
    return this.change$;
  }

  check() {
    return this.tokenService.valid();
  }

  // login(username: string, password: string, rememberMe = false) {
  //   return this.loginService.login(username, password, rememberMe).pipe(
  //     tap(token => this.tokenService.set(token)),
  //     map(() => this.check()),
  //   );
  // }

  login(username: string, password: string, rememberMe = false) {
    this.loggedIn = true;
    this.logger.log('user logged now:', this.loggedIn);
    return this.loginService.login(username, password, rememberMe).pipe(
      tap(token => this.tokenService.set(token)),
      map(() => this.check()),
    );

  }

  refresh() {
    return this.loginService
      .refresh(filterObject({ refresh_token: this.tokenService.getRefreshToken() }))
      .pipe(
        catchError(() => of(undefined)),
        tap(token => this.tokenService.set(token)),
        map(() => this.check())
      );
  }

  logout() {
    return this.loginService.logout().pipe(
      tap(() => this.tokenService.clear()),
      map(() => !this.check())
    );
  }


  user() {
    return this.user$.pipe(share());
  }

  menu() {
    return iif(() => this.check(), this.loginService.menu(), of([]));
  }

  private assignUser() {
    if (!this.check()) {
      return of({}).pipe(tap(user => this.user$.next(user)));
    }

    if (!isEmptyObject(this.user$.getValue())) {
      return of(this.user$.getValue());
    }

    return this.loginService.me().pipe(tap(user => this.user$.next(user)));
  }

  // registerUser(data): Observable<any> {
  //   this.logger.log(data.dateOfBirth);
  //   return this.http.post<any>(`${this.baseUrl}/signup`, data);
  // }

  // rememberDevice(data): Observable<any> {
  //   return this.http.post<any>(`${this.baseUrl}/rememberdevice`, data);
  // }

  emailVerify(verifyEmail: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/verifyEmail`, verifyEmail);
  }

  setpassword(setPWdone: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/setPWDone`, setPWdone);
  }

  // mobileVerify(mobileVerify): Observable<any> {
  //   return this.http.post<any>(`${this.baseUrl2}/verifyMobile`, mobileVerify);
  // }

  signIn(signedInDone: any): Observable<any> {
    this.logger.log('sign in service called');
    return this.http.post<any>(`${this.baseUrl}/signedIn`, signedInDone);
  }

  isLoggedIn(): boolean {
    return this.loggedIn;
  }

  getUserRole(): string | undefined {
    this.userRole = JSON.stringify(sessionStorage.getItem('User_Group'));
    return JSON.parse(this.userRole);
  }

  // getUserPermissions(userRole:string){
  //   this.userPermissions = JSON.stringify(sessionStorage.getItem('User_Permissions'));
  //   return JSON.parse(this.userPermissions);
  // }

  getUserPermissions(userRole: string, data: object) {
    return this.http.post<any>(`${this.baseUrl}/rolePermission/${userRole}`, data);
  }

}
