<!-- <div class="container"> -->
<mat-nav-list>
  <mat-list-item routerLink="/courses" class="mat-list-item-1"
                 *ngIf="auth.getUserRole() === 'SuperAdmin' || auth.getUserRole() === 'Tutor'">
    <mat-icon mat-list-icon>assignment</mat-icon>
    <span class="nav-item-text">Courses</span>
  </mat-list-item>
  <mat-list-item routerLink="/questionnaire" class="mat-list-item-1"
                 *ngIf="auth.getUserRole() === 'SuperAdmin' || auth.getUserRole() === 'Tutor'">
    <mat-icon mat-list-icon>assignment</mat-icon>
    <span class="nav-item-text">Questionnaire</span>
  </mat-list-item>
  <mat-list-item routerLink="/mainsubject" class="mat-list-item-1"
                 *ngIf="auth.getUserRole() === 'SuperAdmin'">
    <mat-icon mat-list-icon>assignment</mat-icon>
    <span class="nav-item-text">Main Subject</span>
  </mat-list-item>
  <mat-list-item routerLink="/feedback" class="mat-list-item-1"
                 *ngIf="auth.getUserRole() === 'SuperAdmin'">
    <mat-icon mat-list-icon>assignment</mat-icon>
    <span class="nav-item-text">Feedback</span>
  </mat-list-item>
  <mat-list-item routerLink="/allusers" class="mat-list-item-1"
                 *ngIf="auth.getUserRole() === 'SuperAdmin'">
    <mat-icon mat-list-icon>assignment</mat-icon>
    <span class="nav-item-text">Users</span>
  </mat-list-item>
  <mat-list-item routerLink="/test-questionnaires" class="mat-list-item-1"
                 *ngIf="auth.getUserRole() === 'SuperAdmin' || auth.getUserRole() === 'Tutor'">
    <mat-icon mat-list-icon>assignment</mat-icon>
    <span class="nav-item-text">Katex</span>
  </mat-list-item>
  <mat-list-item routerLink="/tutors" class="mat-list-item-1"
                 *ngIf="auth.getUserRole() === 'SuperAdmin'">
    <mat-icon mat-list-icon>assignment</mat-icon>
    <span class="nav-item-text">Tutors</span>
  </mat-list-item>



  <div class="logout-button">
    <mat-list-item class="mat-list-item-1" (click)="logout()">
      <mat-icon mat-list-icon>exit_to_app</mat-icon>
      <span class="nav-item-text">Logout</span>
    </mat-list-item>
  </div>
  <div class="version-class">
    v {{version}}
  </div>
</mat-nav-list>

<mat-menu class="mat-menu-panel" #settingsMenu="matMenu">
  <button mat-menu-item class="menu-1"
          *ngIf="auth.getUserRole() === 'SuperAdmin' || auth.getUserRole() === 'FranchiseAdmin'">
    <mat-list-item routerLink="./branches">
      <mat-icon mat-list-icon>apartment</mat-icon>
      <span class="nav-item-text">Branches</span>
    </mat-list-item>
  </button>

  <button mat-menu-item class="menu-1">
    <mat-list-item routerLink="./rooms">
      <mat-icon mat-list-icon>developer_board</mat-icon>
      <span class="nav-item-text">Rooms</span>
    </mat-list-item>
  </button>

  <button mat-menu-item class="menu-1">
    <mat-list-item routerLink="./tutors">
      <mat-icon mat-list-icon>supervised_user_circle</mat-icon>
      <span class="nav-item-text">Tutors</span>
    </mat-list-item>
  </button>
  <button mat-menu-item class="menu-1"
          *ngIf="auth.getUserRole() === 'SuperAdmin' || auth.getUserRole() === 'FranchiseAdmin' ">
    <mat-list-item routerLink="./settings/mainsubjects">
      <mat-icon mat-list-icon>subject</mat-icon>
      <span class="nav-item-text">Subjects</span>
    </mat-list-item>
  </button>
  <button mat-menu-item class="menu-1"
          *ngIf="auth.getUserRole() === 'SuperAdmin' || auth.getUserRole() === 'FranchiseAdmin'">
    <mat-list-item routerLink="./companyDetails">
      <mat-icon mat-list-icon>account_box</mat-icon>
      <span class="nav-item-text">Company Details</span>
    </mat-list-item>
  </button>
  <button mat-menu-item class="menu-1"
          *ngIf="auth.getUserRole() === 'SuperAdmin' || auth.getUserRole() === 'FranchiseAdmin'">
    <mat-list-item routerLink="./session-manage">
      <mat-icon mat-list-icon>account_box</mat-icon>
      <span class="nav-item-text">Manage Sessions</span>
    </mat-list-item>
  </button>
  <button mat-menu-item class="menu-1"
          *ngIf="auth.getUserRole() === 'SuperAdmin' || auth.getUserRole() === 'FranchiseAdmin'">
    <mat-list-item routerLink="./courseContent">
      <mat-icon mat-list-icon>school</mat-icon>
      <span class="nav-item-text">Course Content</span>
    </mat-list-item>
  </button>
</mat-menu>

<mat-menu #attendanceMenu="matMenu">
  <button mat-menu-item class="menu-1">
    <mat-list-item routerLink="./attendance/tutor-attendance">
      <mat-icon mat-list-icon>supervised_user_circle</mat-icon>
      <span class="nav-item-text">Tutor Attendance</span>
    </mat-list-item>
  </button>
  <button mat-menu-item class="menu-1">
    <mat-list-item routerLink="./attendance/student-attendance">
      <mat-icon mat-list-icon>person</mat-icon>
      <span class="nav-item-text">Student Attendance</span>
    </mat-list-item>
  </button>
</mat-menu>
<!-- </div> -->