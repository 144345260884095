import { Component, OnInit } from '@angular/core';
import { FeedbackService } from './feedback.service'
import { QuestionnaireService } from '../questionnaire/questionnaire.service'
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  feedbackData: any;
  showAll = false;
  loadingVisible = false
  popupVisible = false;
  feedback: any;

  constructor(
    private feedbackService: FeedbackService
  ) { }

  ngOnInit() {
    this.loadInitialData()
  }

  loadInitialData() {
    this.loadingVisible = true
    this.feedbackService.getFeedback().subscribe((res) => {
      this.loadingVisible = false
      this.feedbackData = res.data.Items

      this.feedbackData.sort((a: { CreatedOn: string | number | Date; }, b: { CreatedOn: string | number | Date; }) => {
        return new Date(b.CreatedOn).getTime() - new Date(a.CreatedOn).getTime();
      });
      console.log("feeedback data", this.feedbackData);
    }, (err) => {
      this.loadingVisible = false
      console.log(err);
    });
  }

  clickShowAll() {
    this.showAll = !this.showAll
  }



  markFeedbackResolved() {
    this.popupVisible = false;
    const data = {
      SK: this.feedback.SK,
    }
    this.loadingVisible = true
    this.feedbackService.updateFeedback(data).subscribe((res) => {
      this.loadingVisible = false
      notify({
        message: "Feedback marked as Resolved", type: 'success',
        width: 300, shading: true
      }, { position: "top right", direction: "up-push" });
      console.log("feedback updated successfullyy: ", res);
      this.loadInitialData()

    }, (err) => {
      console.log(err);
    });
  }

  hidePopupRevoke() {
    this.popupVisible = false;
  }

  onResolveClicked(feedback: any) {
    this.popupVisible = true;
    this.feedback = feedback
  }

}
