import { Component, Input, ViewEncapsulation, HostListener } from '@angular/core';
import { AuthService, MenuService } from '@core';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { AmplifyService } from '@core/aws-amplify';
import { environment } from '@env/environment';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidemenuComponent {
  // Note: Ripple effect make page flashing on mobile
  @Input() ripple = false;

  menu$ = this.menu.getAll();

  buildRoute = this.menu.buildRoute;
  appropriateClass: string = '';
  version: any;

  @HostListener('window:resize', ['$event'])
  getScreenHeight() {
    if (window.innerHeight <= 412) {
      this.appropriateClass = 'bottomRelative';
    } else {
      this.appropriateClass = 'bottomStick';
    }
  }

  constructor(private menu: MenuService,
    public auth: AuthService,
    private logger: NGXLogger,
    private router: Router,
    private amplifyService: AmplifyService) {
    this.version = environment.version;
  }

  clickStudent() {
    sessionStorage.setItem("navigateFromParent", 'false');
    this.getScreenHeight();
  }

  logout() {
    this.auth.logout().subscribe(() => {
      this.router.navigateByUrl('/auth');
      this.amplifyService.auth().signOut();
      this.amplifyService.setAuthState({ state: 'signOut', user: null });
    });
  }
}
