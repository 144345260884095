
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getFeedback(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getFeedback`);
  }

  updateFeedback(data: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/updatefeedback`, data);
  }


}
