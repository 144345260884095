<div>

    <div class="row">
        <div class="col-md-8">
            <div class="col-md-12">
                <h3>Feedback</h3>
            </div>
            <div class="row mb-2 bread-crumb">
                <div class="col-md-12"><a href="/feedback">Feedback</a> > View All</div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="row mb-3">
                <div class="col-md-9 custom-align">
                    <!-- <input type="text" placeholder="Search" class="form-control custom-search" /> -->
                </div>
                <div class="col-md-3 custom-align">
                </div>
            </div>
        </div>
    </div>

    <div class="mt-3" #feedbackCard>
        <dx-load-panel
                       #loadPanel
                       [position]="{ of: '#feedbackCard' }"
                       [showIndicator]="true"
                       [showPane]="true"
                       [(visible)]="loadingVisible"
                       [shading]="true"
                       [closeOnOutsideClick]="false">
        </dx-load-panel>
        <mat-card class="feedback-card"
                  *ngFor="let feedback of (showAll ? feedbackData: feedbackData?.slice(0, 5))">
            <!-- <mat-card-header class="feedback-header">
                <mat-card-title class="feedback-title">{{feedback.UserEmail}}</mat-card-title>
                <mat-card-subtitle>{{feedback.CreatedOn | date:'dd-MM-yyyy'}}</mat-card-subtitle>
            </mat-card-header> -->
            <mat-card-header class="feedback-header">
                <div class="topic-bar">
                    <div>
                        <mat-card-title
                                        class="feedback-title">{{feedback.UserEmail}}</mat-card-title>
                        <mat-card-subtitle>{{feedback.CreatedOn |
                            date:'dd-MM-yyyy'}}</mat-card-subtitle>
                    </div>

                </div>
                <label (click)="onResolveClicked(feedback)"
                       *ngIf="(feedback.TicketStatus !== 'Resolved')" class="tick-button mt-2"
                       matTooltip="Mark as Resolved">
                    <mat-icon>check</mat-icon>
                </label>

                <label
                       *ngIf="(feedback.TicketStatus === 'Resolved')" class="tick-button2 mt-2"
                       matTooltip="Resolved">
                    <mat-icon>check</mat-icon>
                </label>
            </mat-card-header>

            <mat-card-content class="mt-4">
                <p>
                    {{feedback.Feedback}}
                </p>
            </mat-card-content>

        </mat-card>

        <div *ngIf="feedbackData?.length > 5" class="d-flex justify-content-end mt-3 mb-3">
            <button mat-button class="feedback-btn" (click)="clickShowAll()">
                {{ showAll ? 'See Less' : 'See All' }}
            </button>
        </div>
    </div>

</div>

<dx-popup
          [(visible)]="popupVisible"
          [showTitle]="true"
          title="Mark as Resolved"
          [width]="350"
          [height]="'auto'">
    <div class="row">
        <div class="col-md-12">
            <span class="popupText mb-4 text-center">Mark feedback as Resolved</span>
        </div>
    </div>

    <div class="row d-flex mt-4">
        <div class="col d-flex">
            <button (click)="markFeedbackResolved()" type="button" mat-stroked-button
                    class="btn thin-border"><span
                      id="cnt-text"
                      class="d-flex ">Yes</span></button>
        </div>
        <div class="col d-flex justify-content-end">
            <button (click)=" hidePopupRevoke()" type="button" mat-stroked-button
                    color="warn"
                    class="btn thin-border"><span
                      id="cnt-text"
                      class="d-flex ">No</span></button>
        </div>
    </div>
</dx-popup>