<div class="">
  <div class="d-flex  align-items-center justify-content-center w-100 mt-5" *ngIf="show">

    <mat-card class="m-auto mt-5" style="max-width: 380px;">
      <mat-card-header class="m-b-24">
        <mat-card-title>Set Password</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <h6 class="subheader">Because you accessed the portal using a temporary password,
          you need to update it to your own password at this time.</h6>
        <form class="form-field-full" [formGroup]="newPasswordForm">
          <mat-form-field appearance="outline">
            <mat-label>password</mat-label>
            <input matInput placeholder="Password" class="form-control"
                   [type]="!hide ? 'password' : 'text'"
                   name="password" formControlName="password" (focus)="passwordFocus=true"
                   oncopy="return false" onpaste="return false" oncut="return false"
                   (focusout)="passwordFocus=false">
            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' :'visibility_off'
              }}</mat-icon>
            <mat-error *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <mat-error *ngIf="f.password.errors?.required">Password is required.</mat-error>
              <mat-error *ngIf="f.password.errors?.pattern">
                Password must contain at least one number<br>
                Password must contain at least one @#$* symbol <br>
                Password must contain at least one uppercase letter<br>
                Password must contain at least one lowercase letter<br>
                Password length must be greater than 7 characters<br></mat-error>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Confirm Password</mat-label>
            <input matInput placeholder="Confirm Password" class="form-control"
                   [type]="!hide ? 'password' : 'text'" name="confirmPassword"
                   formControlName="confirmPassword"
                   (focus)="confirmPasswordFocus=true" (focusout)="confirmPasswordFocus=false"
                   oncopy="return false" onpaste="return false" oncut="return false">
            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' :'visibility_off'
              }}</mat-icon>
            <mat-error *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
              <mat-error *ngIf="f.confirmPassword.errors?.required">Confirm Password is
                required.</mat-error>
              <mat-error *ngIf="f.confirmPassword.errors?.mustMatch">Password & Confirm Password
                does not match.
              </mat-error>
            </mat-error>
          </mat-form-field>

          <button class="w-full m-y-16" mat-raised-button color="primary"
                  [disabled]="!!newPasswordForm.invalid" [loading]="loading"
                  (click)="onSubmit()">Submit</button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>