import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from './auth.service';
import { AmplifyService, AuthState } from '@core/aws-amplify';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
})
export class AuthComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  // animationTypes = ngxLoadingAnimationTypes;
  loading = true;
  authState: AuthState = {
    state: 'loading',
    user: null,
  };

  constructor(private amplifyService: AmplifyService, private router: Router, private logger: NGXLogger, private route: ActivatedRoute,
    private authService: AuthService,
  ) {
    this.subscribe();
  }

  ngOnInit() {
    if (!this.amplifyService.auth()) {
      throw new Error('Auth module not registered on AmplifyService provider');
    } else {
      this.amplifyService
        .auth()
        .currentAuthenticatedUser()
        .then((user: any) => {
          if (this.authState.state === 'loading' && user) {
            this.amplifyService.setAuthState({
              state: 'signedIn',
              user,
            });
          }
        })
        .catch((e: any) => {
          if (this.authState.state === 'loading') {
            this.route.queryParamMap.subscribe(queryParams => {
              const username = queryParams.get('email');
              const password = queryParams.get('temp');
              // const password = decodeURIComponent(queryParams.get('temp'));
              if (username && password) {
                this.amplifyService
                  .auth()
                  .signIn(username, password)
                  .then((user: any) => {
                    this.amplifyService.setAuthState({
                      state: 'requireNewPassword',
                      user,
                    });
                    // event data
                    const verifyEmail = 'Email Verify';
                    this.logger.log('Email Verify');
                    this.authService.emailVerify(verifyEmail).subscribe(
                      (ress: any) => {
                        this.logger.log('Email Verify in event', ress);
                      },
                      (error: any) => {
                        this.logger.log('Email Verify in error', error);
                      }
                    );
                  })
                  // tslint:disable-next-line: no-shadowed-variable
                  .catch((e: any) => {
                    this.amplifyService.setAuthState({ state: 'verificationFailed', user: null });
                    this.logger.error(`Error verification Failed: ${JSON.stringify(e)}`);
                  });
              } else {
                this.amplifyService.setAuthState({
                  state: 'signIn',
                  user: null,
                });
              }
            });
          }
        });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  subscribe() {
    this.amplifyService.authStateChange$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        state => {
          this.loading = !(state.state === 'signIn' || state.state === 'signUp' ||
            state.state === 'requireNewPassword' || state.state === 'confirmSignIn' ||
            state.state === 'verificationFailed' || state.state === 'confirmSignUp' ||
            state.state === 'forgotPassword' || state.state === 'signedOut');
          this.authState = state;
          if (state.state === 'signedIn') {
            const userEmail = JSON.stringify(sessionStorage.getItem('User_Email'));
            if (userEmail === 'null') {
              this.logger.log('user email:', userEmail, typeof userEmail)
              this.amplifyService.auth().signOut();
              this.amplifyService.setAuthState({ state: 'signOut', user: null });
              this.router.navigateByUrl('/auth');
              return;
            }
            this.router.navigate(['questionnaire']);
            // this.router.navigate(['pages'], { queryParams: { state: 'signedIn' } });
          }
        },
        () => {
          this.authState = {
            state: 'signIn',
            user: null,
          };
        }
      );
  }

  onSignIn() {
    this.amplifyService.setAuthState({ state: 'signIn', user: null });
  }
}
