import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoggerModule } from 'ngx-logger';
import { AuthComponent } from './auth.component';
import { AuthRoutingModule } from './auth-routing.module';
import { SignInComponent } from './sign-in-component/sign-in.component';
import { RequireNewPasswordComponent } from './require-new-password-component/require-new-password.component';
import { SharedModule } from '@shared/shared.module';
import { MaterialModule } from 'app/material.module';
import { ForgotPasswordComponent } from './forgot-password-component/forgot-password.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoggerModule.forChild(),
    // TooltipModule,
    AuthRoutingModule,
    SharedModule,
    MaterialModule
  ],
  declarations: [
    SignInComponent,
    RequireNewPasswordComponent,
    AuthComponent,
    ForgotPasswordComponent
  ],
  providers: []
})
export class AuthModule { }
