import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TutorsService {
  private generateUrl = environment.apiUrl + '/tutors';

  constructor(private http: HttpClient) { }

  getAllTutors(): Observable<any> {
    return this.http.get(`${this.generateUrl}`);
  }

  createTutor(data: any): Observable<any> {
    return this.http.post(`${this.generateUrl}`, data);
  }

  updateTutor(data: any): Observable<any> {
    return this.http.put(`${this.generateUrl}/update-tutor`, data);
  }

  removeTutor(data: any): Observable<any> {
    return this.http.put(`${this.generateUrl}/delete-tutor`, data);
  }


}
