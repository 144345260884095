import { Component, Input, OnInit, Inject, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthState, AmplifyService } from '@core/aws-amplify';
import { environment } from '@env/environment';
import { MustMatch } from '@shared/validators/must-match.validator';
import { NGXLogger } from 'ngx-logger';

// import PasswordField from 'material-ui-password-field'

@Component({
  selector: 'app-auth-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnChanges {
  // tslint:disable-next-line:variable-name
  _authState!: AuthState;
  show!: boolean;
  // hide: boolean;
  errorMessage!: any;
  isCodeSent = false;
  destination = '';
  submitted = false;
  loading = false;
  forgotPasswordForm: FormGroup;
  updatePasswordForm: FormGroup;
  completed = false;
  counterToShow!: number;
  emailaddress!: string;
  interval!: NodeJS.Timer;
  hide: any;
  emailFocus!: boolean;
  errormsg = false;

  constructor(
    @Inject(AmplifyService) protected amplifyService: AmplifyService,
    private formBuilder: FormBuilder,
    private logger: NGXLogger
  ) {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(environment.emailRegex)]]
    });

    this.updatePasswordForm = this.formBuilder.group({
      code: ['', [Validators.required, Validators.pattern(environment.securityCodeRegex)]],
      password: ['', [Validators.required, Validators.pattern(environment.passwordRegex)]],
      confirmPassword: ['', [Validators.required]]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.isCodeSent ? this.updatePasswordForm.controls : this.forgotPasswordForm.controls;
  }

  @Input()
  set authState(authState: AuthState) {
    this._authState = authState;
    this.show = authState.state === 'forgotPassword';
    // if(authState.state === 'forgotPassword') {
    //   this.isCodeSent = false;
    //   this.completed = false;
    // }

    this.forgotPasswordForm.patchValue({
      email: authState.user && authState.user.email ? authState.user.email : ''
    });
  }

  clearData() {
    this.updatePasswordForm.reset();
  }

  ngOnInit() {
    if (!this.amplifyService.auth()) {
      throw new Error('Auth module not registered on AmplifyService provider');
    }


  }
  onSignUp() {
    clearInterval(this.interval);
    this.submitted = false;
    this.isCodeSent = false;
    this.forgotPasswordForm.reset();
    this.updatePasswordForm.reset();
    this.onAlertClose();
    this.completed = false;
    this.amplifyService.setAuthState({ state: 'signUp', user: { email: this.forgotPasswordForm.value.email } });

  }

  ngOnChanges() {
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
  }

  onSend() {
    if (this.isCodeSent === false) {
      this.submitted = true;
    }
    this.logger.log(this.forgotPasswordForm.value);
    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.onAlertClose();
    const email = (this.forgotPasswordForm.value.email as string).trim().toLowerCase();
    this.loading = true;
    this.amplifyService
      .auth()
      .forgotPassword(email)
      .then((res: any) => {
        this.logger.log(`Forgot password request completed`, res);
        this.isCodeSent = true;
        // this.restructureEmailaddress(res.CodeDeliveryDetails.Destination);
        // this.destination = res.CodeDeliveryDetails.Destination;
        this.emailaddress = res.CodeDeliveryDetails.Destination;
      })
      .catch((err: any) => {
        this.logger.error(`Forgot password request failed`, JSON.stringify(err));
        if (err.message === 'User password cannot be reset in the current state.') {
          this.errormsg = true;
          this._setError('You are not verified. Please try to Register again.');
        } else {
          this._setError(err.message);
        }
        this.isCodeSent = false;
      })
      .finally(() => {
        this.loading = false;
        this.submitted = false;
      });
  }

  // restructureEmailaddress(Destination: any) {
  //   const lastFour = Destination.substr(Destination.length - 4);
  //   let email: string[] = Destination.split('@');
  //   this.emailaddress = '*******@' + email[1];
  // }

  onSubmit() {
    this.submitted = true;
    this.logger.log(this.updatePasswordForm.value);
    // stop here if form is invalid
    if (this.updatePasswordForm.invalid) {
      return;
    }
    const email = this.forgotPasswordForm.value.email;
    const code = this.updatePasswordForm.value.code.toString();
    const password = this.updatePasswordForm.value.password;
    this.loading = true;
    this.onAlertClose();
    this.amplifyService
      .auth()
      .forgotPasswordSubmit(
        email,
        code,
        password
      )
      .then((res: any) => {
        this.logger.log(`update password request completed`, res);
        this.onAlertClose();
        this.completed = true;
        this.startCountdown();
        // setTimeout(() => {
        //   this.amplifyService.setAuthState({ state: 'signIn', user: { email: this.forgotPasswordForm.value.email } });
        //   this.completed = false;
        // }, 300000);
      })
      .catch((err: any) => {
        this.logger.error(`update password request completed`, JSON.stringify(err));
        this._setError(err);
      })
      .finally(() => {
        this.loading = false;
        this.submitted = false;
      });
  }

  onSignIn() {
    clearInterval(this.interval);
    this.submitted = false;
    this.isCodeSent = false;
    this.forgotPasswordForm.reset();
    this.updatePasswordForm.reset();
    this.onAlertClose();
    this.completed = false;
    this.amplifyService.setAuthState({ state: 'signIn', user: { email: this.forgotPasswordForm.value.email } });
  }

  onAlertClose() {
    this._setError(null);
  }

  _setError(err: any) {
    if (!err) {
      this.errorMessage = null;
      return;
    }
    this.errorMessage = err.message || err;
  }
  // click(){
  //   hide: true;
  // }

  startCountdown() {
    let counter = 10;

    this.interval = setInterval(() => {
      this.logger.log(counter);
      this.counterToShow = counter;
      counter--;
      if (counter < 0) {

        clearInterval(this.interval);
        this.amplifyService.setAuthState({ state: 'signIn', user: { email: this.forgotPasswordForm.value.email } });
        this.completed = false;
        this.isCodeSent = false;

      }
    }, 1000);
  }
}
